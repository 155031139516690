import axios from '@axios'

const PREFIX = 'parcelamentos/pgfn'
export default {

  getPaginadoEmpresa(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged-group?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  baixaEmLote(filtros, nomeArquivo, agrupamento) {
    return axios.post(`${PREFIX}/baixa-lotes-empresa?nomeArquivo=${nomeArquivo}&agrupamento=${agrupamento}`, filtros)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },
}

<template>
  <b-card>
    <download-em-lote
      tipo-arquivo="parcelamentos PGFN"
      :tipo="`Parcelamento PGFN`"
      style="white-space: nowrap;"
      possui-agrupador
      :agrupadores="['Arquivo Único', 'Por Empresa']"
      @baixar-lote="baixarEmLote($event.nomeArquivo, $event.agrupador)"
    >
      <template slot="avisos-baixa-lote">
        <div
          v-b-tooltip.hover
          title="A consulta junto a Procuradoria Geral da Fazenda Nacional é feita diariamente das 7:00 às 21:00 horas"
          style="padding-right: 5px;"
          class="text-primary"
        >
          <feather-icon
            icon="InfoIcon"
            size="32"
            stroke-width="0.8"
          />
        </div>
      </template>
    </download-em-lote>

    <parcelamento-list
      id="pgfnList"
      ref="pgfnList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      ultima-rota="emissao_pgfn_Parcelamento-view"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <div
          v-if="props.column.field === 'parcelasEmAtraso'"
          class="text-center"
        >
          <b-badge
            v-if="props.row['parcelasEmAtraso'] != null"
            :variant="props.row['parcelasEmAtraso']? 'light-danger' : 'light-success'"
          >
            {{ props.row['parcelasEmAtraso']? 'Sim' : 'Não' }}
          </b-badge>
          <div
            v-else
          >
            -
          </div>
        </div>
        <div
          v-if="props.column.field === 'erroCadastro'"
          class="text-center d-block"
          style="font-size: 16px;"
        >
          <span
            v-b-tooltip.hover
            :title="props.row[props.column.field] == false ? '' : 'Criar perfil no Regularize'"
          >
            <feather-icon
              v-if="props.row[props.column.field] == false"
              icon="CheckIcon"
              style="font-size: 16px;color:green"
            />
            <font-awesome-icon
              v-else-if="props.row[props.column.field] == true"
              icon="fa-solid fa-xmark"
              style="color:red;font-size: 16px;"
            />
            <span
              v-else
              style="font-size: 16px;"
            >
              -
            </span>
          </span>
        </div>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <span
          v-b-tooltip.hover
          :title="props.row.parcelasEmAtraso == null && props.row.erroCadastro == false ? 'Não foram encontradas solicitações de negociação para este contribuinte.' : 'Visualizar Parcelamentos'"
        >
          <b-btn
            variant="none"
            style="padding: 0.2rem;"
            :disabled="props.row.parcelasEmAtraso == null || props.row.erroCadastro"
            @click="visualizarEmissaoParcelamento(props.row)"
          >
            <font-awesome-icon
              icon="fa-money-check-dollar"
              size="xl"
            />
          </b-btn>
        </span>
      </template>
    </parcelamento-list>
  </b-card>
</template>

<style>
.regularize{
  width: 10px;
}
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import api from './shared/services/api'

export default {
  name: 'ParcelamentoPgfn',
  components: {
    FontAwesomeIcon,
    ParcelamentoList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
  },
  data() {
    return {
      botoesOption: null,
      linha: null,
      visualizacao: {
        colunas: [
          {
            label: 'Regularize', field: 'erroCadastro', type: 'custom', tdClass: 'regularize',
          },
          { label: 'Razão social', field: 'razaoSocial' },
          { label: 'CNPJ/CPF', field: 'cadastroFederal' },
          {
            label: 'Parcelas em atraso', field: 'parcelasEmAtraso', type: 'custom', tdClass: 'col-tamanho-cnpj',
          },
          { label: 'Consultado em', field: 'consultadoEm' },
        ],
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'caixapostal-td-acao',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'ConsultadoEm'], valor: null }, { colunas: ['RegularizeNotNull'], valor: true }],
      filtrosRapidos: [
        {
          id: 'EmAtraso',
          colunas: ['EmAtraso'],
          label: 'Em atraso',
          valor: '2',
        },
        {
          id: 'EmDia',
          colunas: ['EmDia'],
          label: 'Em dia',
          valor: '1',
        },
        {
          id: 'ComParcelamento',
          colunas: ['ComParcelamento'],
          label: 'Com Parcelamento',
          valor: true,
        },
        {
          id: 'SemParcelamento',
          colunas: ['ComParcelamento'],
          label: 'Sem Parcelamento',
          valor: false,
        }],
      filtroAtual: {},
    }
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  created() {
    this.visualizacao.evento = this.carregar
  },
  methods: {
    /* eslint-disable no-param-reassign */
    carregar(pagina, tamanhoPagina, filtros) {
      this.filtroAtual = filtros
      return api.getPaginadoEmpresa(pagina, tamanhoPagina, filtros)
    },
    baixarEmLote(nomeArquivo, agrupador) {
      api.baixaEmLote(this.filtroAtual, nomeArquivo, agrupador)
    },
    visualizarEmissaoParcelamento(row) {
      this.$router.push({
        name: 'emissao_pgfn_Parcelamento-view',
        params: { id: row.id, nome: `${row.cadastroFederal} - ${row.razaoSocial}` },
      })
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.pgfnList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.pgfnList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
  },
}
</script>
